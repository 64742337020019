import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import { usePosthogStore } from '~/stores/posthog';

export const useUserStore = defineStore('user', () => {
    const posthogStore = usePosthogStore();
    const supabaseUser = useSupabaseUser();
    const supabase = useSupabaseClient();

    const {
        public: {
            appSuperAdminEmail,
        }
    } = useRuntimeConfig();

    const route = useRoute();
    const { $i18n, $toast, $supabaseClient } = useNuxtApp();
    const { getLocaleCookie, getBrowserLocale, setLocale } = $i18n;
    
    const isOpenMobileSidebar = ref(false);
    const workspaces = ref<{
        id: string;
        name: string;
        role: string;
        is_mask_contact_info: boolean;
        user_workspace_id: string;
    }[]>([]);
    const workspaceMembers = ref<WorkspaceMember[]>([]);

    watch(() => route.path, () => {
        isOpenMobileSidebar.value = false;
    }, { immediate: true });

    const userIdValuePairs = computed(() => {
        return workspaceMembers.value.reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
        }, {} as Record<string, WorkspaceMember>);
    })

    const defaultLanguage = computed<'en' | 'zh-CN' | 'zh-TW'>(() => {
        const locale = getLocaleCookie() ?? (getBrowserLocale() ?? 'en');
        return ['en', 'zh-CN', 'zh-TW'].includes(locale) ? locale : 'en';
    });

    const isAgentRole = computed(() => {
        return user.value?.role === 'agent';
    })

    const isSuperAdmin = computed(() => {
        return supabaseUser.value?.email === appSuperAdminEmail;
    })

    const currentWorkspace = computed(() => {
        return workspaces.value.find((workspace) => workspace.id === supabaseUser.value?.app_metadata?.workspace_id);
    })

    const user = computed(() => {
        if (!supabaseUser.value) return {};
        return {
            id: supabaseUser.value.id,
            uid: supabaseUser.value.app_metadata?.user_id,
            email: supabaseUser.value.email,
            emailVerified: true,
            role: currentWorkspace.value?.role,
            displayName: supabaseUser.value.user_metadata?.full_name,
            workspaceId: supabaseUser.value.app_metadata?.workspace_id,
            isInFreePlan: supabaseUser.value.app_metadata?.is_subscribing_free_plan ?? true,
        }
    })

    const navigationItems = computed(() => {
        if (isAgentRole.value) return fixedNavigationItems;
        return [
            ...fixedNavigationItems,
            ...ownerAndManagerOnlyNavigationItems,
            {
                label: 'Upgrade',
                path: '/subscriptions/plans',
                icon: 'i-heroicons-arrow-trending-up',
            },
            {
                label: 'Settings',
                path: '/settings/whatsapp',
                icon: 'i-lucide-settings',
            },
        ]
    })

    watch(defaultLanguage, (newValue) => {
        dayjs.locale(newValue);
        setLocale(newValue);
    }, { immediate: true })

    async function signOut() {
        const { error } = await supabase.auth.signOut(); 
        if (error) {
            $toast.error($i18n.t('auth.toast.error.logoutFailed'));
            throw error;
        }
        posthogStore.reset();
        window.location.href = '/auth/login';
    }

    watchEffect(() => {
        if (import.meta.client
            && !route.path.startsWith('/auth')
            && supabaseUser.value
            && (!supabaseUser.value?.app_metadata?.user_id || !supabaseUser.value?.app_metadata?.workspace_id)
        ) {
            setUserAppMetadata();
        }
    });

    async function setUserAppMetadata(workspaceId?: string, role = 'owner') {
        try {            
            const res = await $fetch('/api/user/app-metadata', {
                method: 'POST',
                body: {
                    workspaceId,
                    role,
                }
            });
            if (res.status === 200) {
                await $supabaseClient.auth.refreshSession();
            }
        } catch (error) {
            throw error;
        }
    }

    async function fetchWorkspaces() {
        const { data, error } = await supabase
            .from('user_workspace')
            .select(`
            user_workspace_id:id,
            ...workspaces (
                id,
                name,
                is_mask_contact_info
            ),
            ...roles (
                role:name
            )
            `)
            .eq('user_id', supabaseUser.value?.app_metadata?.user_id);
        if (error) throw error;
        workspaces.value = data;
        return data;
    }

    async function fetchTeamMembers() {
        try {            
            const res = await $fetch('/api/team/members', {
                method: 'GET',
            })
            workspaceMembers.value = res.teamMembers;
        } catch (error) {
            throw error;
        }
    }

    async function switchWorkspace({
        id,
        role,
        name,
    }: {
        id: string;
        role: string;
        name: string;
    }) {
        if (id === user.value?.workspaceId) return;    
        try {            
            await setUserAppMetadata(id, role);
            $toast.success(`You are now in the ${name}`);
            setTimeout(() => {
                window.location.href = '/inbox';
            }, 1.5 * 1000);
        } catch (error) {
            $toast.error('Failed to switch workspace');
            throw error;
        }
    }

    return {
        user,
        defaultLanguage,
        workspaceMembers,
        isOpenMobileSidebar,
        userIdValuePairs,
        workspaces,
        navigationItems,
        currentWorkspace,
        isSuperAdmin,
        isAgentRole,
        signOut,
        fetchWorkspaces,
        fetchTeamMembers,
        switchWorkspace,
	};
});

export const fixedNavigationItems = [
    {
        label: 'Inbox',
        path: '/inbox',
        icon: 'i-lucide-inbox',
    },
]

export const ownerAndManagerOnlyNavigationItems = [
    {
        label: 'Contacts',
        path: '/contacts',
        icon: 'i-lucide-contact',
    },
    {
        label: 'Custom Objects',
        path: '/custom-objects',
        icon: 'i-lucide-database',
    },
    {
        label: 'Agents',
        path: '/agents',
        icon: 'i-lucide-bot',
    },
    {
        label: 'Workflows',
        path: '/workflows',
        icon: 'i-lucide-network',
    },
    {
        label: 'Reports',
        path: '/reports',
        icon: 'i-lucide-line-chart',
        disabled: true,
    }
]

export const roleDescription = {
    owner: "Full access to everything in the workspace",
    manager: "Full access to everything in the workspace, except add/delete owner", 
    agent: "Read-only access to the workspace, but have full access to the assigned conversations and contacts",
}

interface WorkspaceMember {
    id: string;
    name: string;
    email: string;
    role: string;
}
